<template>
	<div>
		<div v-if="firstTarget < 1">
			Invalid input
		</div>
		<div
			v-else
			class="flex flex-col justify-between md:flex-row"
		>
			<div class="w-full md:w-1/3">
				<WwCollapse
					class="h-tag-nav-wrap"
					:expanded="!isMobile"
					:collapsable="isMobile"
				>
					<template #title>
						<span class="flex items-center mb-2 text-black toc-head">
							<img
								src="@/assets/icons/list.svg"
								alt="List"
								height="16"
								width="16"
							> Table of Contents
						</span>
					</template>
					<template #content>
						<HTagNavItem
							:schema="schema"
							:active-tag-id="activeTagId"
						/>
					</template>
				</WwCollapse>
			</div>
			<div
				class="w-full mt-2 prose h-tag-nav-content md:w-2/3 md:px-6"
				v-html="content"
			/>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

export default {
	components: {
		HTagNavItem: () => import('@/components/multiUse/HTagNavItem.vue'),
		WwCollapse: () => import('@/components/multiUse/WwCollapse.vue')
	},
	props: {
		markup: {
			type: String,
			default: ''
		},
		levels: {
			type: Number,
			default: 2
		}
	},
	data () {
		return {
			mimeType: 'text/html',
			tempDom: {},
			htmlContent: {},
			prefix: 'h-tag',
			schema: [],
			activeTagId: '',
			firstTarget: 1,
			overrideIO: false,
			isMounted: false
		}
	},
	computed: {
		navItemNaming () {
			return `${this.prefix}-nav`
		},
		navContentNaming () {
			return `${this.prefix}-content`
		},
		navTargetNaming () {
			return `${this.prefix}-target`
		},
		lastTarget () {
			return this.firstTarget + this.levels
		},
		nodeTargets () {
			const tempTargets = []
			for (let i = this.firstTarget; i <= this.lastTarget; i++) {
				tempTargets.push(`H${i}`)
			}
			return tempTargets
		},
		content () {
			return this.isMounted ? this.htmlContent?.outerHTML : this.markup
		},
		...mapGetters([ 'isMobile' ]),
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		}
	},
	watch: {
		isMounted: {
			handler: async function () {
				this.hookItUp()
			}
		},
		markup() { // use new markup when state changes
			this.isMounted = false
		},
		content() { // triggers new schema to be built when state changes
			this.isMounted = true
		}
	},
	mounted () {
		// TODO update this to check the array of available hashes
		if (this.$route.hash) {
			this.activeTagId = this.$route.hash.substring(1)
			this.overrideIO = true
		}
		window.onhashchange = () => {
			this.overrideIO = true // set this so the item clicked is the one active.  It stops the intersection observer from setting it
			this.activeTagId = this.$route.hash.substring(1)
		}
		this.isMounted = true
	},
	methods: {
		addLink (parent, data) {
			const tempLink = { children: [], data: data }
			if (!parent.children) {
				parent.push(tempLink)
			} else {
				parent.children.push(tempLink)
			}
			return tempLink
		},
		scrollSpied (entries) {
			if (this.overrideIO === true) {
				this.overrideIO = false
				return
			}

			for (const i in entries) {
				if (entries[i].isIntersecting === true) {
					this.activeTagId = entries[i].target.id
					return
				}
			}
		},
		setStart () {
			const tempElements = Array.from(this.htmlContent.children)
			for (let i = 1; i <= (this.levels + 1); i++) {
				const tempTag = `H${i}`
				const result = tempElements.filter(element => element.tagName === tempTag)
				if (result.length > 0) {
					this.firstTarget = i
					return
				}
			}
		},
		buildNav () {
			// TODO this could be better commented and explained
			const domParser = new DOMParser() // setup a domParser to parse the dynamic markup passed in

			this.tempDom = domParser.parseFromString(`<nav id="${this.navItemNaming}" class="navbar navbar-light"></nav><div data-spy="scroll" id="${this.navContentNaming}">${this.markup}</div>`, this.mimeType)

			this.htmlContent = this.tempDom.getElementById(`${this.navContentNaming}`)

			this.setStart() // figure out what the highest level htag is in the markup passed in

			let lastLevel = 0
			let navParent = this.schema
			let lastElement
			for (const i in this.htmlContent.children) {
				const thisChild = this.htmlContent.children[i]
				if (this.nodeTargets.indexOf(thisChild.tagName) < 0) continue // exclude any tags that aren't in the target list of h tags

				const thisLevel = parseInt(thisChild.tagName.substring(1))
				const delta = thisLevel - lastLevel

				if (delta > 1 && lastLevel > 0) continue // this means there is something like an h3 nested in an h1.  To keep the levels consistent they have to go only 1 level at a time

				if (thisLevel !== this.firstTarget && lastLevel === 0) continue // this means we have a nested level before a top level so we need to exclude it

				const tagId = thisChild.innerText.replace(/ /g, '-').trim()
				thisChild.id = tagId
				thisChild.classList.add(this.navTargetNaming)

				const data = {
					content: thisChild.innerText, tagId: tagId, level: thisLevel
				}

				if (delta === 1 && typeof lastElement === 'object') { // parent stays at root on first loop else if going 1 level deep then go under the last one
					navParent = lastElement
				} else if (delta <= -1) { // this is breaking out of nesting x levels deep
					// TODO currently only supports stepping back up from h4 > h3 > h2 > h1 [UNTESTED]
					// REVIEW needs more testing
					if (thisLevel === 1) {
						navParent = this.schema
					} else if (thisLevel === 2) {
						const tempLevel = this.schema[this.schema.length - 1]
						navParent = tempLevel
					} else if (thisLevel === 3) {
						const schemaLevel = this.schema[this.schema.length - 1]
						const tempLevel = schemaLevel.children[this.schemaLevel.children.length - 1]
						navParent = tempLevel
					}
				}

				lastElement = this.addLink(navParent, data)
				lastLevel = thisLevel
			}
		},
		async hookItUp () {
			this.schema = []
			await this.buildNav()
			if (this.browserSupportsIO) {
				const observer = new IntersectionObserver(this.scrollSpied)
				const scrollTargets = document.getElementsByClassName(`${this.navTargetNaming}`)

				for (let i = 0; i < scrollTargets.length; i++) {
					observer.observe(scrollTargets[i])
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.h-tag-nav-content img {
	width: 100%; /* .w-full */
}

.h-tag-nav-content li {
	width: 100%;
	word-break: break-all;
}

.h-tag-nav-wrap {

	position: sticky;
	top: 70px;
	.toc-head {
		background: none;
		border: 0;
		width: 100%;
		text-align: left;
		padding: 0;

		@media only screen and (min-width: $break-md) {
			background: none;
			pointer-events: none;
			text-align: left;
			padding: 0;
			font-size: 1.15em;
			font-weight: 600;
		}
		img {
			width: 16px;
			margin-right: 5px;
		}
	}
	.collapse {

		width: 100%;

		@media only screen and (max-width: $break-md) {
			display: none;
			&.show {
				display: inline-block;
			}
		}
	}
	ul {
		margin: 0;
		padding: 0;
		ul {
			margin: 0 0 0 10px;
		}
	}
	li {
		margin: 0 0 5px 0;
	}
	.active a {
		color: #1ec36a; /* .text-green-500 */
		font-weight: bold; /* .font-bold */
	}
}
</style>
